require('platform/disqus.js');

$(function(){

	/*
	*
		Mobile navigation
	*
	*/


	// Burger Menu mobile
	$(".main-menu-burger").click(function(e){
		e.preventDefault();
		// first off all, close the other menu
		if($(".search-menu").hasClass("open")){
			$(".search-menu, .search-menu-button").removeClass("open");
			$(".search-menu").hide();
		}
		if(!$(this).hasClass("open")){
			$(this).addClass("open");
			$(".main-menu").addClass("open").fadeIn();

		}else{
			$(this).removeClass("open");
			$(".main-menu").removeClass("open").fadeOut();

		}
	});

	// Submenu mobile
	if(!$('.main-menu a.CMS_lvl2').hasClass('no-plus')) {
		$(".main-menu a.CMS_lvl2").append("<span class='plus'></span>");
		$(".main-menu a.CMS_lvl2 .plus").click(function(e){
			e.preventDefault();
			if(!$(this).parent().hasClass("open")){
				$(this).parent().addClass("open");
				$(this).addClass("open");
				$(this).parent().next().addClass("open").fadeIn();
			}else{
				$(this).removeClass("open");
				$(this).parent().removeClass("open");
				$(this).parent().next().removeClass("open").fadeOut();
			}
		})
			$(".main-menu.mobile span.CMS_lvl2 ").click(function(e){
				e.preventDefault();
				if(!$(this).hasClass("open")){
					$(this).addClass("open");
					$(this).addClass("open");
					$(this).next().addClass("open").fadeIn();
				}else{
					$(this).removeClass("open");
					$(this).removeClass("open");
					$(this).next().removeClass("open").fadeOut();
				}
			})
		}

	// Submenu mobile
	$(".main-menu span.CMS_lvl2").append("<span class='plus'></span>");
	$(".main-menu span.CMS_lvl2 .plus").click(function(e){
		e.preventDefault();
		if(!$(this).parent().hasClass("open")){
			$(this).parent().addClass("open");
			$(this).addClass("open");
			$(this).parent().next().addClass("open").fadeIn();
		}else{
			$(this).removeClass("open");
			$(this).parent().removeClass("open");
			$(this).parent().next().removeClass("open").fadeOut();
		}
	});

	// Search Form mobile
	$(".search-menu-button").click(function(e){
		e.preventDefault();
		// first off all, close the other menu
		if($(".main-menu").hasClass("open")){
			$(".main-menu-burger, .main-menu").removeClass("open");
			$(".main-menu").hide();
		}
		if(!$(this).hasClass("open")){
			$(this).addClass("open");
			$(".search-menu").addClass("open").fadeIn();

		}else{
			$(this).removeClass("open");
			$(".search-menu").removeClass("open").fadeOut();

		}
	});

	accordeon();

	/*
	*
		Desktop navigation
	*
	*/

	// Search Form standard
	$(".search-more").click(function(e){
		e.preventDefault();
		if(!$(this).hasClass("open")){
			$(this).addClass("open");
			$(".search-more-wrapper").addClass("open")

		}else{
			$(this).removeClass("open");
			$(".search-more-wrapper").removeClass("open");
		}
	});

	if($(window).width() > 990){

		// Scroll to top
		$(document).on( 'scroll', function(){
			if ($(window).scrollTop() >= 400) {
				$('.go-to-top').fadeIn();
			} else {
				$('.go-to-top').fadeOut();
			}
		});

		// Scroll sidebar

		if($('.second-column').length && $(window).width() > 1600){
			var stickyTop = $('.second-column').offset().top;
			var footerPosition = $('#footer').position().top;
			$(document).on( 'scroll', function(){
				// Position of the bottom of the sidebar
				var stickyTopBottom = $('.second-column').offset().top + $('.second-column').height();
				// If windows height is bigger than the sidebar
				var menu_height = $('#main-menu-container').height();
				if (window.innerHeight > $('.second-column').height()) {
					// If scrolled passed the top of the sidebar, make it sticky
					if ($(window).scrollTop() >= (stickyTop - menu_height - 10)) {
						var delta = 260;
						if(($(window).scrollTop() + $('.second-column').height() + 250) < $(document).height()){
							$('.second-column').css({ top: ($(window).scrollTop() - delta)});
						}
					}else{
						$('.second-column').css({ top: "0"});
					}
				}
			});
		}

		// Sticky menu
		var stickyTopMenu = 0;

		if ($('.sub-menu').length) {
			stickyTopMenu = $('#header').outerHeight() + $('.sub-menu').outerHeight();
		} else {
			stickyTopMenu = $('#header').outerHeight();
		}

		// var H = $('#main-menu-container').outerHeight();

		$(document).on( 'scroll', function(){
			if ($(window).scrollTop() > stickyTopMenu+1) {
				$('body:not(.platform) #main-menu-container').addClass("fixed");
				$('body:not(.platform) .sub-menu, body:not(.platform) .sub-menu-dsv, body:not(.platform) .top-image').addClass("fixed");
				$('#header').css('margin-bottom', stickyTopMenu);
			}else{
				if($('#main-menu-container').hasClass("fixed")) {$('body:not(.platform) #main-menu-container, body:not(.platform) .sub-menu, body:not(.platform) .sub-menu-dsv, body:not(.platform) .top-image').removeClass("fixed");};
				$('#header').css('margin-bottom', "");
			}
		});

	}else{
		/* Responsive table */
		$("#main-content table").wrap("<div style='width:100%;overflow-x:auto;'></div>");
	}

	/* Datepicker Filters */
	$('.input-wrapper.date input').datepicker({
		dateFormat: "mm/yy",
		changeMonth: true,
		changeYear: true,
		showButtonPanel: true,
		closeText: 'Ok',
		showClearButton : true,
		onClose: function(dateText, inst) {
			function isDonePressed(){
				return ($('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
			}
			function isClearPressed(){
				return ($('#ui-datepicker-div').html().indexOf('ui-datepicker-clear ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
			}
			if (isDonePressed()){
				var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
				var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
				$(this).datepicker('setDate', new Date(year, month, 1)).trigger('change');

                 $('.input-wrapper.date input').focusout()//Added to remove focus from datepicker input box on selecting date
             }
             if (isClearPressed()){
             	$.datepicker._clearDate(this);
             }
         },
         beforeShow : function(input, inst) {
         	inst.dpDiv.addClass('month_year_datepicker')
         	if ((datestr = $(this).val()).length > 0) {
         		year = datestr.substring(datestr.length-4, datestr.length);
         		month = datestr.substring(0, 2);
         		$(this).datepicker('option', 'defaultDate', new Date(year, month-1, 1));
         		$(this).datepicker('setDate', new Date(year, month-1, 1));
         		$(".ui-datepicker-calendar").hide();
         	}
         }
     });


	/* Page Department store of the world */
	if($(".dsotw").length){
		$(".dsotw .continentWrapper").each(function(){
			// Populate Select
			var select = $(this).find("select");
			$(".country", this).each(function(){
				select.append("<option value='" + $(this).text() + "'>" + $(this).text() + "</option>");
			});
		});
		$(".dsotw .continentWrapper .close").click(function(){
			$(this).parent().fadeOut().parent().parent().removeClass("open");
		});
		$(".dsotw .continentWrapper select").change(function(){
			var selected = $(this).val();
			selected = selected.replace(/ /g,'-');
			selected = selected.replace(/\./g,'-');
			console.log(selected);
			$(".containerCountry:not('."+selected+"')").hide().parent().parent().removeClass("open");
			$(".containerCountry."+selected).fadeIn().parent().parent().addClass("open");
		});
	}

	/* Fancybox */
	$(".middle-content a").fancybox();

	/* Slider homepage sidebar */
	$('.home-add').slick({
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		arrows:false,
		slidesToShow: 1,
		slidesToScroll: 1,
	});

	/* Slider for mobile homepage*/
	$('.upcoming-activities .container-slide').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 2,
		responsive: [
		{
			breakpoint: 8000, /* disable on desktop */
			settings: "unslick"
		},
		{
			breakpoint: 998,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
		]
	});

	/* Slider for homepage public (V2) */
	$('.section-members .slider').slick({
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		arrows:true,
		slidesToShow: 3,
		slidesToScroll: 1,
	});

	/* Print function */
	if(window.location.search.substring(1) == "print=1"){
		window.print();
	}
	/* Continent list */
	if($('.continent').length){
		//$('.continent').css({overflow:'hidden',height: $(this).find('.title').height()});
		$('.continent .title').click(function(){
			if($(this).parent().hasClass('open')){
				$(this).parent().removeClass('open');
				//$(this).parent().animate({height: $(this).find('.title').height()},200);
			}else{
				$('.continent').removeClass('open');
				$(this).parent().addClass('open');
				//$(this).parent().animate({height: '200px'},200);
			}
		});
	}

	/* Slider if not in admin */
	if($('.slider-simple').length){
		/* If not in admin */
		if($("#sidePanel").length == 0){
			simple_slider();
		}
	}

	if($("body").hasClass("homepage") && window.innerWidth < 992){
		// Replace titles on homepage mobile version (only for exploration : 1 && inspiration : 3)
		var itemmenu = $(".homepage-container").eq(1).find(".item-menu");
		itemmenu.hide();
		$(".homepage-container").eq(1).prepend("<div class='item item-menu'>"+itemmenu.html()+"</div>");
		var itemmenu = $(".homepage-container").eq(3).find(".item-menu");
		itemmenu.hide();
		$(".homepage-container").eq(3).prepend("<div class='item item-menu'>"+itemmenu.html()+"</div>");
	}

	/* Popup on items click */
	eventItem();

	/* Populate item lists */
	if($(".populate").length){
		var loader = $("<img src='/public/images/pictos/preloader.gif' class='loading' />");
		$(".populate").html( loader );
		$(".pager-more").hide();
		var _id = false;
		var _type = false;
		// Search
		if($("body").hasClass("search")){
			var search = window.location.search;
			var is_search = search.indexOf("?search");
			var is_tag = search.indexOf("?tag");
			if(is_search != -1){
				_type = "search";
				_id = "general";
				loadItems(_id, _type, 1, 12, 'override', search);
			}
			else if(is_tag != -1){
				_type = "searchTag";
				_id = search.replace("?tag=","");
				loadItems(_id, _type, 1, 12, 'override');
			}


		}
		// Theme lvl2
		/*else if($("body").hasClass("level_2")){
			_id = $("body").attr("class").replace("level_2", "").replace("home", "").trim();
			_type = "theme";
			loadItems(_id, _type);
		}*/
		// Rubrique lvl3 & lvl4
		else if($("body").hasClass("level_4") || $("body").hasClass("level_5")){
			_id = $("body").attr("class").replace("level_4", "").replace("level_5", "").trim();
			_type = "rubrique";

			if(_id == "meetingreports activities  nfooter") {
				loadMultiplesItems("operation activities  nfooter", _type);
				loadMultiplesItems("merchandise activities  nfooter", _type);
				loadMultiplesItems("ceo activities  nfooter", _type);
			} else {
				loadItems(_id, _type);
			}
		}

	}

	/* Pager */
	$(".pager-more").click(function(e){
		e.preventDefault();
		var _type = "rubrique";
		var formvalues = $(".form-filters").serialize();
		var page = 2;
		if(!$(this).attr("data-pager")){
			$(this).attr("data-pager", "1");
		}else{
			$(this).attr("data-pager", parseInt($(this).attr("data-pager")) + 1);
			page = parseInt($(this).attr("data-pager")) + 1;
		}
		var _id = _id = $("body").attr("class").replace("level_3", "").trim();

		var _type = "rubrique";
		if($("body").hasClass("level_2")){
			_id = $("body").attr("class").replace("level_2", "").replace("home", "").trim();
		}
		// case search
		if($("body").hasClass("search")){
			var search = window.location.search;
			var is_search = search.indexOf("?search");
			var is_tag = search.indexOf("?tag");
			if(is_search != -1){
				_id = "general";
				_type = "search";
				formvalues = "search=" + $("#main-menu-container form.search .search-input").val();
			}
			else if(is_tag != -1){
				_type = "searchTag";
				_id = search.replace("?tag=","");
				formvalues = "";
			}



		}
		var loader = $("<img src='/public/images/pictos/preloader.gif' class='loading' />");
		$(".populate").append( loader );

		loadItems(_id, _type, page, 12, 'append', "?"+formvalues);
	});

	/* Filters */
	$(".form-filters").submit(function(e){
		e.preventDefault();
		var formvalues = $(this).serialize();
		var _id = _id = $("body").attr("class").replace("level_3", "").trim();
		var _type = false;
		if($("body").hasClass("level_2")){
			_id = $("body").attr("class").replace("level_2", "").replace("home", "").trim();
		}
		var loader = $("<img src='/public/images/pictos/preloader.gif' class='loading' />");
		$(".populate").html( loader );
		loadItems(_id, 'rubrique', 1, 12, 'override', "?"+formvalues);
	});


}); /* Dom ready */


var xhttp = new XMLHttpRequest();
function loadItems(_id, _type, _start, _end, _method, _extraArgs) {
	if(typeof(_id) == "undefined") _id = "";
	if(typeof(_type) == "undefined") _type = "theme";
	if(typeof(_start) == "undefined") _start = 1;
	if(typeof(_end) == "undefined") _end = 12;
	if(typeof(_method) == "undefined") _method = "prepend";
	if(typeof(_extraArgs) == "undefined") _extraArgs = "";

	/* Only take the 'parent' if {grandparent:codename} + {parent:codename} are passed */
	_id = _id.split(/(\s+)/);
	if(_id.length ){
		_id = _id[0];
	}
	console.info(" - Ajax load items from MainModule : ",_id + _extraArgs, _type, _start, _end, _method);

	xhttp.onreadystatechange = function() {
		
		if (this.readyState == 4 && this.status == 200) {
			
			var html = this.responseText;

			$(".loading").remove();

			if(_method == "prepend"){
				$(".populate").hide();
				$(html).prependTo(".populate").imagesLoaded().then(function(){
					sameHeightImg();

				});
				$(".populate").fadeIn(200);
			}else if(_method == "append"){
				$(html).appendTo(".populate").imagesLoaded().then(function(){
					sameHeightImg();
				});
			}else{
				$(".populate").html(html).imagesLoaded().then(function(){
					sameHeightImg();
				});
			}

    	// Remove pager if no items available
    	if(!$(".paginate").length){
    		$(".pager-more").hide();
    	}else{
    		$(".pager-more").show();
    	}
    	$(".paginate").remove();

    	// event on items (modal)
    	eventItem();
    }
};
if(_type == "rubrique") xhttp.open('GET', '/mainautomnemodule/search/rubric/' + _id + '/' + _end + '/' + _start + _extraArgs, true);
  else if(_type == "theme") xhttp.open('GET', '/mainautomnemodule/search/theme/' + _id + '/8/0' + _extraArgs, true); // TODO : a changer /!\ Sur activities
  else if(_type == "search") xhttp.open('GET', '/mainautomnemodule/search/'+ _id + '/12/'+ _start + '/' +  _extraArgs, true);
  else if(_type == "searchTag"){
  	xhttp.open('GET', '/mainautomnemodule/search/tag/'+ _id + '/' + _end + '/' + _start, true);
  }

  xhttp.send();
}











var xhttp1 = new XMLHttpRequest();
var xhttp2 = new XMLHttpRequest();
var xhttp3 = new XMLHttpRequest();
function loadMultiplesItems(_id, _type, _start, _end, _method, _extraArgs) {
	if(typeof(_id) == "undefined") _id = "";
	if(typeof(_type) == "undefined") _type = "theme";
	if(typeof(_start) == "undefined") _start = 1;
	if(typeof(_end) == "undefined") _end = 500;
	if(typeof(_method) == "undefined") _method = "prepend";
	if(typeof(_extraArgs) == "undefined") _extraArgs = "";

	/* Only take the 'parent' if {grandparent:codename} + {parent:codename} are passed */
	_id = _id.split(/(\s+)/);
	if(_id.length ){
		_id = _id[0];
	}
	//console.info(" - Ajax load items from MainModule : ",_id + _extraArgs, _type, _start, _end, _method);


	if(_id == "operation") {
		xhttp1.open('GET', '/mainautomnemodule/search/rubric/' + _id + '/' + _end + '/' + _start + _extraArgs, true);
		xhttp1.send();
	}

	if(_id == "merchandise") {
		xhttp2.open('GET', '/mainautomnemodule/search/rubric/' + _id + '/' + _end + '/' + _start + _extraArgs, true);
		xhttp2.send();
	}

	if(_id == "ceo") {
		xhttp3.open('GET', '/mainautomnemodule/search/rubric/' + _id + '/' + _end + '/' + _start + _extraArgs, true);
		xhttp3.send();
	}

	xhttp1.onreadystatechange = function() {
		if (this.readyState == 4 && this.status == 200) {
			var html = this.responseText;
			$(".loading").remove();

			if(_method == "prepend"){
				$("#zoneope").hide();
				$(html).prependTo("#zoneope").imagesLoaded().then(function(){
					sameHeightImg();

				});
				$("#zoneope").fadeIn(200);
			}else if(_method == "append"){
				$(html).appendTo("#zoneope").imagesLoaded().then(function(){
					sameHeightImg();
				});
			}else{
				$("#zoneope").html(html).imagesLoaded().then(function(){
					sameHeightImg();
				});
			}

    	// Remove pager if no items available
    	if(!$(".paginate").length){
    		$(".pager-more").hide();
    	}else{
    		$(".pager-more").show();
    	}
    	$(".paginate").remove();

    	// event on items (modal)
    	eventItem();
			addtrie();
    }

	xhttp2.onreadystatechange = function() {
		if (this.readyState == 4 && this.status == 200) {
			var html = this.responseText;
			$(".loading").remove();

			if(_method == "prepend"){
				$("#zonemerch").hide();
				$(html).prependTo("#zonemerch").imagesLoaded().then(function(){
					sameHeightImg();

				});
				$("#zonemerch").fadeIn(200);
			}else if(_method == "append"){
				$(html).appendTo("#zonemerch").imagesLoaded().then(function(){
					sameHeightImg();
				});
			}else{
				$("#zonemerch").html(html).imagesLoaded().then(function(){
					sameHeightImg();
				});
			}

    	// Remove pager if no items available
    	if(!$(".paginate").length){
    		$(".pager-more").hide();
    	}else{
    		$(".pager-more").show();
    	}
    	$(".paginate").remove();

    	// event on items (modal)
    	eventItem();
			addtrie();
    }}

	xhttp3.onreadystatechange = function() {
		if (this.readyState == 4 && this.status == 200) {
			var html = this.responseText;
			$(".loading").remove();

			if(_method == "prepend"){
				$("#zoneceo").hide();
				$(html).prependTo("#zoneceo").imagesLoaded().then(function(){
					sameHeightImg();

				});
				$("#zoneceo").fadeIn(200);
			}else if(_method == "append"){
				$(html).appendTo("#zoneceo").imagesLoaded().then(function(){
					sameHeightImg();
				});
			}else{
				$("#zoneceo").html(html).imagesLoaded().then(function(){
					sameHeightImg();
				});
			}

    	// Remove pager if no items available
    	if(!$(".paginate").length){
    		$(".pager-more").hide();
    	}else{
    		$(".pager-more").show();
    	}
    	$(".paginate").remove();

    	// event on items (modal)
    	eventItem();
			addtrie();
    }}

};
}

















function extractHtml(_xml) {
	var dom = "";
	dom = _xml.querySelector('data').innerHTML;
	return dom;
}


function parseXml(xml) {
	var dom = null;
	if (window.DOMParser) {
		try {
			dom = (new DOMParser()).parseFromString(xml, "text/xml");
		}
		catch (e) { dom = null; }
	}
	else if (window.ActiveXObject) {
		try {
			dom = new ActiveXObject('Microsoft.XMLDOM');
			dom.async = false;
         if (!dom.loadXML(xml)) // parse error ..

         	window.alert(dom.parseError.reason + dom.parseError.srcText);
     }
     catch (e) { dom = null; }
 }
 else
 	alert("cannot parse xml string!");
 return dom;
}


function simple_slider(){
	$('.slider-nav').each(function(index, el) {
		$(this).html($(this).prev('.slider-simple').html());
	});

	$('.slider-simple').slick({
		dots: false,
	  //infinite: true,
	  speed: 300,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  //asNavFor: '.slider-nav',
	  mobileFirst: true,
	  //adaptiveHeight: true,

	});
	$(window).trigger('resize');

	/*$('.slider-nav').slick({
		slidesToShow: 10,
		slidesToScroll: 1,
		asNavFor: '.slider-simple',
		dots: false,
		centerMode: false,
		focusOnSelect: true
	});*/
}
window.simple_slider = simple_slider;

function addtrie(){
	var years = $('.item').map(function() {
		return $(this).data('year');
	}).get();
	//console.log("years",years);

	var unique = years.filter(function(itm, i, years) {
		return i == years.indexOf(itm);
	});

	var firstyear = unique[0];

	$('#triyears').html('');
	
	$.each(unique, function( index, value ) {
		/*alert( index + ": " + value );*/
		$('#triyears').append( '<div class="buttontriyear btnyear'+value+'" data-yeartosort="'+value+'">'+value+'</div>' );
	});

	$( ".item" ).hide();
	$( ".item.annee" +firstyear).fadeIn();
	$( ".btnyear" +firstyear).addClass("active");

	$( ".buttontriyear" ).on( "click", function() {
		yeartoshow = $(this).data('yeartosort');
		$( ".item" ).hide();
		$( ".item.annee" +yeartoshow).fadeIn();
		$( ".buttontriyear").removeClass("active");
		$( ".btnyear" +yeartoshow).addClass("active");
	});
}

function eventItem(){
	$(".item:not(.item-menu) a:not(.item-alk-rubric, .item-alk-nomod), a.item:not(.item-pdf)").click(function(e){
		var $item = $(this);
		var title = "";
		if($('.item-title',$item).length) title = $('.item-title',$item).html().trim();

		if ($(this).text() == 'Our partners') {
			return;
		}

		//Google analytics event
		if(typeof AUTOMNE_USER != "undefined"){
			ga('send','event',AUTOMNE_USER.company,AUTOMNE_USER.fullName,title);
		}
		// Save the theme
		var theme = $(this).attr("class").replace("item-alk","").trim();

		// Check the type
		var type = $(this).attr("data-type");

		if(type == "topic"){
			if($('.item-alk-rubric',$item.parent()).length){
				var categoryTitle = $('.item-alk-rubric',$item.parent()).html().trim();
				title = categoryTitle + " - " + title;
			}
		}

		if(type == "document"){
			//console.log(type);

		}else if(type == "external"){
			//console.log(type);

		}else{
			e.preventDefault();

			// Ajax call
			var url = "";
			if($(this).attr("href") == "#") url = '404'
				else {
					url = $(this).attr("href").replace(/^.*\/\/[^\/]+/, '').replace("/", "").replace(/\/$/, "");
				}
				$(".item-modal").remove();
				$(".item-modal-overlay").remove();
				var pop = $("<div class='item-modal'><div class='content'></div><button class='close'></button></div>\
					<div class='item-modal-overlay'></div>").appendTo("#main-content");

				if (window.innerWidth > 992) {
					if($(window).scrollTop() < $("#main-menu-container").offset().top){
						$(".item-modal").css({top: $("#main-content").offset().top - $(window).scrollTop()});
						$('.item-modal').height($(window).height() - $("#main-content").offset().top + $(window).scrollTop());
					}else{
						$('.item-modal').height($(window).height() - $("#main-menu-container").height());
					}
				}

				$("body").css({overflow:'hidden', 'paddingRight':'12px'});
				pop.animate({
					right:0
				},400);

				//console.log("Page requested",url);
				var requestedPage = url;
				if(url == '403'){
					url = window.location.origin + '/' + url+"/";
				}else{
					url = "/"+url;
				}

				$( ".item-modal .content" ).load( url+" #main-content" , function(response, status, xhr){


					if ( xhr.status == "403" ) {

						$( ".item-modal .content" ).load( "/403/ #main-content" );

					}else{
						var restricted_access = false;
						var data = xhr.responseText;
						if($('div.access-restricted',data).length){
							ga('send','event',AUTOMNE_USER.company,AUTOMNE_USER.fullName,'403 - Access restricted');
							//console.log("Access restricted logged", AUTOMNE_USER.company,AUTOMNE_USER.fullName,'403 - Access restricted');
							restricted_access = true;
						}

						$('.item-modal').addClass(theme);
					// Slider when image are loaded
					$("#main-content").imagesLoaded().then(function(){
						simple_slider();
						// Scrollbar
						$('.item-modal #main-content').addClass('nano');
						$('.item-modal #main-content > div').addClass('nano-content');
						$('#disqus_thread').removeClass('nano-content');
						$('.content-color-bloc').removeClass('nano-content');
						$('.range-media').removeClass('nano-content');
						$('.nano-pane').removeClass('nano-content');
						//$('.content-color-bloc').appendTo('#main-content .nano-content:first-child');
						//$('.range-media').appendTo('#main-content .nano-content:first-child');
						$('#disqus_thread').appendTo('#main-content .nano-content');
						$(".nano").nanoScroller({ alwaysVisible: true });

						// VOLET LOAD

						if(type == "topic"){
							//uncomment to enable disqus from homepage for topics
							window.loadDisqus($item.attr('href'),$item.data('disqus-id'),$item.data('disqus-title'));
						}

						accordeon();

						//send analytics event
						(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
							(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
							m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
						})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
						ga('create', 'UA-96450754-1', 'auto');
						ga('send', { 'hitType': 'pageview', 'page': '/'+requestedPage });


						/*Add recaptcha to ajax loaded white paper form*/
						if ($('#g-recaptcha').length) {
							recaptchaSiteKey = $( "#g-recaptcha" ).data("sitekey");
							grecaptcha.render('g-recaptcha', {
								sitekey: recaptchaSiteKey,
								callback: function(response) {
									//console.log(response);
								}
							});
						}


							//          if(!restricted_access){

							//           // Load discus
							//           var disqus_config = function () {
							//           	this.page.url = url;
							//           };

							// 	(function() { // DON'T EDIT BELOW THIS LINE
							// 		var d = document, s = d.createElement('script');
							// 		s.src = 'https://iads-org.disqus.com/embed.js';
							// 		s.setAttribute('data-timestamp', +new Date());
							// 		(d.head || d.body).appendChild(s);
							// 	})();
							// }
						});


				}



			});

				var currentUrl = window.location.href;
				var currentTitle = document.title;

				var requestedUrl = url;
				window.history.pushState({urlPath:requestedUrl},"",requestedUrl);
				document.title = title;

				$(".item-modal-overlay").data('page-url',currentUrl);

				$(".item-modal .close, .item-modal-overlay").click(function(e){
					e.preventDefault();
					var pageUrl = $(".item-modal-overlay").data('page-url');
					window.history.pushState({urlPath:pageUrl},"",pageUrl);
					document.title = currentTitle;

					$(".item-modal-overlay").remove();
					pop.animate({
						right:"-100%"
					},300,function(){
						$(".item-modal").remove();
						$("body").css({overflow:'auto', 'paddingRight':'0'});
					});
				});
			}
		});
}
window.eventItem = eventItem;
 // ###### ACCORDEON CONTENT PUBLIC #######


 // On déclare des variables
 function accordeon() {

 	var accordeon_container = $('.accordeon');
 	var accordion_head = $('.accordeon div.accordeon-titre-trigger');
 	var accordion_body = $('.accordeon div.accordeon-texte');

 	accordion_body.hide();

 	// Au clic sur le titre d'un accordeon
 	accordion_head.click(function(){

 		accordion_head.not($(this)).removeClass('open');
		// On ajoute/supprime la class "open" sur l'accordeon
		$(this).toggleClass('open');
 		// On referme tous les autres "body" des accordeons
 		accordion_body.not($(this).next()).slideUp('normal');
 		// On ouvre ou ferme la div "body" de l'accordeon
 		$(this).next().slideToggle('normal');
 		div.hasClass("open").child().css("background-color", "red");
 	});
 }
 window.accordeon = accordeon;


 // ###### END ACCORDEON CONTENT PUBLIC #######


 function removeBaseUrl(url) {
 	var baseUrlPattern = /^https?:\/\/[a-z\:0-9.]+/;
 	var result = "";
 	var match = baseUrlPattern.exec(url);
 	if (match != null) {
 		result = match[0];
 	}
 	if (result.length > 0) {
 		url = url.replace(result, "");
	    url = url.replace("/", ""); // first slash
	    url = url.replace(/\/$/, ""); // trailling slash
	}
	return url;
}
function sameHeightImg() {
	if ($('.homepage').length > 0) {
		var baseSizeH = $('.highlights .item:nth-child(2) img').outerHeight();
		var baseSizeW = $('.highlights .item:nth-child(2) img').outerWidth();
		$('.item-link.platform img').css('height',baseSizeH).css('width',baseSizeW);
		sameHeight('.item-link');
	}
}

function sameHeight(selector){
	if (window.innerWidth > 768) {

		var $div = $(selector);

		if($("body").hasClass('homepage')){

			$(".homepage-container").each(function(){
				$div = $(this).find(selector);
	    		//console.log("SAMEHEIGHT by container", $(this));
	    		// On all items in page
	    		var heights = [];
	    		$.each($div, function(index, value) {
	    			heights.push(value.offsetHeight);
	    		  //console.log(value.offsetHeight);
	    		});
	    		var maxHeight = Math.max.apply(null, heights);
	    		//console.log('set Maxheight : ', maxHeight, $div);
	    		$div.height(maxHeight - 20);
	    	});
			if (window.innerWidth > 985) {
				$(".highlights").each(function(){
		    		// First two
		    		$div = $(this).find(selector);
		    		var heights1 = [];
		    		var heights2 = [];
		    		$.each($div, function(index, value) { //console.log(index); console.log(value.offsetHeight);
		    			if(index < 2) heights1.push(value.offsetHeight);
		    			else heights2.push(value.offsetHeight);
		    		});
		    		var maxHeight1 = Math.max.apply(null, heights1);
		    		var maxHeight2 = Math.max.apply(null, heights2);

		    		$.each($div, function(index, value) {
		    			if(index < 2) $(this).height(maxHeight1 - 20);
		    			else $(this).height(maxHeight2 - 20);
		    		});
		    	});
			}


		}else if($("body").hasClass('level_3') || $("body").hasClass('level_4') || $("body").hasClass('level_5')){
	    	// 4 by 4
	    	var iterator = 0;
	    	var group = 0;
	    	$(selector).each(function(k, v){
	    		iterator++;
	    		if(k%4 == 0) group++;
	    		$(v).addClass("same-size-group-"+group);
	    		//console.log("item", k, k%4, 'group',group);
	    	});

	    	// Same size by group
	    	for (i = 1; i <= group; i++) {
	    		var maxHeight = 0;
	    		var $groupdiv = $(".same-size-group-"+i);
	    		var groupheights = [];
	    		$.each($groupdiv, function(index, value) {
	    			groupheights.push(value.offsetHeight);
	    		});
	    		var maxHeight = Math.max.apply(null, groupheights);
	    		$(".same-size-group-"+i).height(maxHeight - 20);

	    	}
	    }else{
	    	// 2 by 2
	    	var iterator = 0;
	    	var group = 0;
	    	$(selector).each(function(k, v){
	    		iterator++;
	    		if(k%2 == 0) group++;
	    		$(v).addClass("same-size-group-"+group);
	    		//console.log("item", k, k%2, 'group LARGE',group);
	    	});

	    	// Same size by group
	    	for (i = 1; i <= group; i++) {
	    		var maxHeight = 0;
	    		var $groupdiv = $(".same-size-group-"+i);
	    		var groupheights = [];
	    		$.each($groupdiv, function(index, value) {
	    			groupheights.push(value.offsetHeight);
	    		});
	    		var maxHeight = Math.max.apply(null, groupheights);
	    		$(".same-size-group-"+i).height(maxHeight - 20);

	    	}
	    }
	}
}

$(window).resize(function() {
	sameHeightImg();
});

/* Homepage load */
$(window).load(function() {
	if (window.innerWidth > 1100) {

	};
});

// $(window).bind('orientationchange', function(event) {
// 	sameHeight('.item-link');
// 	$('.item-menu-content').each(function(){ /* Chromium debug */
//  		//$(this).height($(this).parent().height() - 20);
//  		$(this).height($(this).parent().parent().find(".item:not(.item-menu)").height() - 30);
//  		//console.log($(this).parent().height());
//  	});
// });

/* Resize handler */
// var rtime;
// var timeout = false;
// var delta = 200;
// $(window).resize(function() {
// 	rtime = new Date();
// 	if (timeout === false) {
// 		timeout = true;
// 		setTimeout(resizeend, delta);
// 	}
// });

// function resizeend() {
// 	if (new Date() - rtime < delta) {
// 		setTimeout(resizeend, delta);
// 	} else {
// 		timeout = false;
//     	// remove preset size
//     	//$(".item-link").attr("style","");
//     	sameHeight('.item-link');
// 		$('.item-menu-content').each(function(){ /* Chromium debug */
// 			//$(this).height($(this).parent().height() - 20);
// 			$(this).height($(this).parent().parent().find(".item:not(.item-menu)").height() - 30);
// 			//console.log($(this).parent().height());
// 		});
//     }
// }

/* Fn Helpers */
$.fn.imagesLoaded = function () {

    // get all the images (excluding those with no src attribute)
    var $imgs = this.find('img[src!=""]');
    // if there's no images, just return an already resolved promise
    if (!$imgs.length) {return $.Deferred().resolve().promise();}

    // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
    var dfds = [];
    $imgs.each(function(){

    	var dfd = $.Deferred();
    	dfds.push(dfd);
    	var img = new Image();
    	img.onload = function(){dfd.resolve();}
    	img.onerror = function(){dfd.resolve();}
    	img.src = this.src;

    });

    // return a master promise object which will resolve when all the deferred objects have resolved
    // IE - when all the images are loaded
    return $.when.apply($,dfds);

}

if ($('.public_content').length > 0) {
	const list = document.querySelector(".public_content");
const listItems = list.querySelectorAll("a");
const ajaxLoadMoreBtn = document.querySelector(".pager-more-fake");
 
let k = 7;
let j = 12;
 
ajaxLoadMoreBtn.addEventListener("click", function () {
  let range = `a:nth-child(n+${k}):nth-child(-n+${j})`;
  list
    .querySelectorAll(range)
    .forEach((elem) => (elem.style.display = "inline-block"));
 
  if (listItems.length <= j) {
    this.remove();
  } else {
    k += 6;
    j += 6;
  }
});
if (listItems.length <= j) {
    ajaxLoadMoreBtn.remove();
  } 
}


