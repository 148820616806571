

var doSubmit = function(event){
	event.preventDefault();

	var $form = $(this);
	var form = $form[0];

	tinymce.triggerSave();

	$('.form-result-failure').remove();

	$form.addClass('was-validated');

	if(form.checkValidity() === false){
		event.stopPropagation();
		onFailure($form,"@todo edouard form non valide");
	}else{

		var data = new FormData(form);

		var url = $form.attr('action');
		var method = $form.attr('method');
		var enctype = $form.attr('enctype');

		$.ajax({
			url: url,
			method: method,
	        processData: false,  // Important!
	        contentType: false,
	        cache: false,
	        data: data
	    })
		.done(function(xhr){
			if( xhr.success){
				onSuccess($form);
			}else{
				var message = "An error occured";
				if(xhr.message){
					message = xhr.message;
				}
				onFailure($form,message);
			}
		})
		.fail(function(){
			onFailure($form,"An error occured");
		})
		.always(function(){
		});
	}
}

function onFailure($form,message){
	var $failureContainer = $('<div/>')
	.addClass('alert alert-danger form-result form-result-failure')
	.append(message);

	$form.prepend($failureContainer);
}

function onSuccess($form){
	var $resultContainer = $('.form-result');
	$form.hide();
	$resultContainer.show();
}

window.initForm = function($form){
	var $resultContainer = $('.form-result');
	$resultContainer.hide();

	$form.on('submit',doSubmit);
	$form.addClass('needs-validation');

	$('.topic-form-btn-submit',$form).on('click',function(){
		$form.submit();
	});

	tinymce.remove();
	tinymce.init({
		selector: '#description',
		menubar: false,
		branding: false
	});

	var inputs = document.querySelectorAll( '.inputFile' );
	Array.prototype.forEach.call( inputs, function( input )
	{
		var label	 = input.nextElementSibling,
		labelVal = label.innerHTML;
		input.addEventListener( 'change', function( e )
		{
			var fileName = '';
			if( this.files && this.files.length > 1 ) {
				fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
			}
			else {
				fileName = e.target.value.split( '\\' ).pop();
			}
			if( fileName ) {
				label.querySelector( 'span' ).innerHTML = fileName;
			}
			else { 
				label.innerHTML = labelVal;
			}
		});
	});

}