require('platform/disqus.js');
require('platform/create-form.js');
require('platform/date.js');

function initPage(){
	initEvents();
	initTopicList();
}

function initEvents(){

	$('.btn-create-topic').click(function(){
		var $item = $(this);
		openModal($item,'form');
	});

	$('#keywords').on('change',function(){
		initTopicList();
	});
}

function initTopicList(){
	var $container = $('.topic-list');

	var params = {};

	params.keywords = $('#keywords').val();

	$container.attr('data-page',0);
	$container.data('page',0);

	loadList(params,true);
}



function loadMore(){

	$('.topic-load-more').remove();
	var $container = $('.topic-list');
	var page =  parseInt($container.data('page'))+1;
	$container.data('page',page);

	var params = {
		page: page,
		keywords: $('#keywords').val()
	};

	loadList(params,false);
}

function loadList(params,resetList){
	if(resetList==null){
		resetList = true;
	}
	var $container = $('.topic-list');
	if(resetList){
		$container.empty();
	}
	var url = $container.data('url');

	removeLoader();
	$container.append(drawLoader());

	var defaultParams = {
		'itemsPerPage': $container.data('length'),
		'page': 0
	};
	var data = $.extend(defaultParams,params);

	$.ajax({
		url: url,
		method: 'GET',
		data: data
	})
	.done(function(xhr){
		if(xhr.success){

			if(xhr.count <= 0){
				drawNoResult();
			}else{
				drawTopics(xhr.data);
				if(xhr.hasMore){
					drawMoreBtn();
				}
			}
		}else{
			var message = "An error occured!";
			if(xhr.message){
				message = xhr.message;
			}
			console.error(message);
		}
	})
	.fail(function(){
		console.error("An error occured !");
	})
	.always(function(){
		removeLoader();
	});
}

function drawNoResult(){
	var $container = $('.topic-list');
	$container.append(
		$('<div/>')
		.addClass('no-result')
		.append(I18N.message.noResult)
	);

}

function drawTopics(topics){
	var $container = $('.topic-list');

	for(var t in topics){
		var topic = topics[t];

		$container.append(drawTopic(topic));
	}


	$('.topic').click(function(e){
		e.preventDefault();
		var $item = $(this);
		openModal($item);
	});
}

function drawTopic(topic){

	var theme = topic.theme.slug;
	var type = topic.type.slug;

	var formatedCreatedAt = window.formatDate(topic.createdAt,' ',true);

	var $content = $('<div/>')
	.addClass('item topic')
	.attr('data-theme',theme)
	.attr('data-type',type)
	.attr('data-disqus-id',topic.disqus)
	.attr('data-disqus-title',topic.pageTitle )
	.attr('href',topic.url)
	.append(
		$('<div/>')
		.addClass('item-link '+theme+' '+type)
		.append(
			$('<a/>')
			.addClass('item-alk')
			.append(
				$('<h4/>')
				.addClass('topic-title')
				.append(topic.title)
				)
			.append(
				$('<div/>')
				.addClass('topic-details')
				.append(
					$('<span/>')
					.addClass('topic-author')
					.append(topic.author.fullName)
					)
				.append(
					$('<span/>')
					.addClass('separator')
					.append('&nbsp;')
					)
				.append(
					$('<span/>')
					.addClass('topic-company')
					.append(topic.company)
					)
				.append(
					$('<span/>')
					.addClass('separator')
					.append('&nbsp;')
					)
				.append(
					$('<span/>')
					.addClass('topic-createdAt')
					.append(formatedCreatedAt)
					)
				)
			.append(
				$('<div/>')
				.addClass('btn-link align-right')
				.append('View')
				)
			)
		);

	return $content;
}

function drawLoader(){
	var $loader = $('<div/>')
	.addClass('topic topic-loader')
	.append( 
		$('<center/>')
		.addClass('loader')
		.append('Loading ...')
	);
	return $loader;
}

function removeLoader(){
	$('.topic-loader','.topic-list').remove();
}

function drawMoreBtn(){
	var $container = $('.topic-list');

	$btnMore = $('<div/>')
	.addClass(' topic topic-load-more')
	.append(
		$('<center/>')
		.append(
			$('<button/>')
			.attr('type','button')
			.addClass('topic-btn-more')
			.append(I18N.message.showMore)
			.on('click',function(){
				loadMore()
			})
			)
		);

	$container.append($btnMore);
}

function openModal($item,mode){
	if(mode == null){
		mode = 'topic';
	}
	$(".item-modal").remove();
	$(".item-modal-overlay").remove();


	var $pop = $("<div/>")
	.addClass('item-modal')
	.append(
		$('<div/>')
		.addClass('content')
		.append('Loading ...')
		)
	.append(
		$('<button/>')
		.addClass('close')
		);

	var $overlay = $('<div/>')
	.addClass('item-modal-overlay')
	.css('position','fixed');

	$pop.appendTo("#main-content");
	$overlay.appendTo("#main-content");

	if (window.innerWidth > 992) {
		if($(window).scrollTop() < $("#main-menu-container").offset().top){
			$pop.css({top: $("#global-content").offset().top - $(window).scrollTop()});
			$overlay.css({top: $("#global-content").offset().top - $(window).scrollTop()});
			$pop.height($(window).height() - $("#global-content").offset().top + $(window).scrollTop());
			$overlay.height($(window).height() - $("#global-content").offset().top + $(window).scrollTop());
		}else{
			$pop.height($(window).height() - $("#main-menu-container").height());
			$overlay.height($(window).height() - $("#main-menu-container").height());
		}
	}
	$("body").css({overflow:'hidden', 'paddingRight':'12px'});


	$pop.animate({right:0},400);
	$overlay.animate({right:0},400);


	var url = $item.attr('href');
	var requestedPage = url;

	if(url == '403'){
		url = window.location.origin + '/' + url+"/";
	}

	$( ".item-modal .content" ).load( 
		url+"?mode=embed #main-content" , 
		function(response, status, xhr){

			if ( xhr.status == "403" ) {
				$( ".item-modal .content" ).load( "/403/ #main-content" );
			}else if ( xhr.status == "404" ) {
				$( ".item-modal .content" ).load( "/404/ #main-content" );
			}else{
				$pop
				.addClass($item.data('theme'))
				.addClass($item.data('type'));

				// Slider when image are loaded
				$("#main-content").imagesLoaded().then(function(){
					window.simple_slider();
		            // Scrollbar
		            $('.item-modal #main-content').addClass('nano');
		            $('.item-modal #main-content > div').addClass('nano-content');
		            $('#disqus_thread').removeClass('nano-content');
		            $('.content-color-bloc').removeClass('nano-content');
		            $('.range-media').removeClass('nano-content');
		            $('.nano-pane').removeClass('nano-content');
		            //$('.content-color-bloc').appendTo('#main-content .nano-content:first-child');
		            //$('.range-media').appendTo('#main-content .nano-content:first-child');
		            $('#disqus_thread').appendTo('#main-content .nano-content');
		            $(".nano").nanoScroller({ alwaysVisible: true });

		            // VOLET LOAD
		            window.accordeon();
		            if(mode == 'topic'){
		            	window.loadDisqus($item.attr('href'),$item.data('disqus-id'),$item.data('disqus-title'));
			            //send analytics event
			            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			            	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			            	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
			            ga('create', UA_KEY, 'auto');
			            ga('send', { 'hitType': 'pageview', 'page': '/'+requestedPage });
			        }
			        if(mode == 'form'){
			        	var $form = $('.topic-create-form');
			        	window.initForm($form);
			        }


			    });
			}

		}
		);

	var currentUrl = window.location.href;
	var currentTitle = document.title;
	var requestedUrl = url;
	var topicTitle = $item.data('disqus-title');

	if(mode == 'topic'){
		window.history.pushState({urlPath:requestedUrl},"",requestedUrl);
		$overlay.data('page-url',currentUrl);
		document.title = topicTitle;
	}


	$(".item-modal .close, .item-modal-overlay").click(function(e){
		e.preventDefault();

		if(mode == 'topic'){
			var pageUrl = $(".item-modal-overlay").data('page-url');
			window.history.pushState({urlPath:pageUrl},"",pageUrl);
			document.title = currentTitle;
		}

		$overlay.remove();
		$pop.animate(
			{right:"-100%"},
			300,
			function(){
				$(".item-modal").remove();
				$("body").css({overflow:'auto', 'paddingRight':'0'});
			}
			);
	});


}


$(document).ready(function(){
	initPage();

})