require("navigation");
require("components/btnCalendar.js");


//new WOW().init();

if ($('.homepagev2').length > 0) {
	new WOW({offset:200}).init();
}

$(document).ready(function(){
	function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    $('#new_popup__close').click(function(e){
    	$(".new_popup").hide();
    	setCookie('new_popup', 1, 365);
    });
});

 // ###### OUVERTURES DES POPUP #######

function popUp() {

	// Au clic des boutons du header
	$('.newsletter-btn, .cta-news a').click(function(e) {
		e.preventDefault();
		$(".bg-popup").css("display","block");
		$(".newsletter-popup").fadeIn(500).css("display","block");
	});
	if($(".newsletter-popup .form-message").length){
		$(".bg-popup").css("display","block");
		$(".newsletter-popup").fadeIn(500).css("display","block");
	}
	$('.login-btn').click(function() {
		onClickLoginBtn();
	});
	$('.login-btn-public').click(function() {
		onClickLoginBtn();
	});
	$('.activ-btn').click(function() {
		$(".bg-popup").css("display","block");
		$(".activation-popup").fadeIn(500).css("display","block");
	});
	$('.activ-btn-public').click(function() {
		$(".bg-popup").css("display","block");
		$(".activation-popup").fadeIn(500).css("display","block");
	});
	$(".bg-popup").click(function() {
		$(".newsletter-popup, .bg-popup, .connect-popup, .activation-popup").fadeOut(500);
		$(".newsletter-popup .form-message").remove();
	});
}
popUp();



function isLoginClickParameter(){
	var url = new URL(window.location.href);
	var clickParameter = url.searchParams.get('click');
	if (clickParameter && clickParameter == 'login') {
		return true;
	}
	return false;
}

function onClickLoginBtn(){
	var $form = $('#cms_forms_2');
	if ($form.length <= 0) {
		redirectToHomeWithLogin();
	}else{
	    showLoginPopup();
	}
}

function showLoginPopup(){
	$(".bg-popup").css("display","block");
	$(".connect-popup").fadeIn(500).css("display","block");
	$('body').toggleClass('popupopened');
}

function redirectToHomeWithLogin(){
	document.location = '/?click=login';
}

function hasDate() {
	$('.data-bloc-single').each(function() {
			if( $(this).children('.data-bloc-single-date').length ) {
				$(this).children('.data-bloc-single-category').addClass('cat-border');
			}
	});
}

function sameHeight(arg){
	var height = 0;
	var element = $(arg);
	element.css('height', '');
	element.each(function() {
		if ($(this).outerHeight(false) > height){
			height = $(this).outerHeight();
		}
	});	
	element.css('height', height);
}

function faq(){
	$('.faq-toggle button').on('click', function() {
		$(this).toggleClass('clicked');
		$('.faq-reponse',$(this).parent()).slideToggle(400,'linear',function () {
        // Animation complete.
    });

	});
	$('.faq-question').on('click', function() {
		$(this).next('button').trigger( "click" );
	});
}

$(document).ready(function() {
	hasDate();
	faq()
	if ($('.platform-search').length > 0) {
		$('.search-button').click(function(e){
			e.preventDefault();
			$('#keywords').submit();
		})
	}
});

$(window).resize(function() {
	if (window.matchMedia("(min-width: 993px)").matches) {
		/* La largeur minimum de l'affichage est 993px px inclus */

	}
	
});

function hauteurEgale(group) {
	tallest = 0;
	group.each(function() {
		thisHeight = $(this).height();
		if(thisHeight > tallest) {
			tallest = thisHeight;		
		}
	});
	group.css('height',tallest+'px');
}

$(window).load(function() {
	if (window.innerWidth > 992) {
		hauteurEgale($(".same-height"));
	};
})


$(document).on('event', '#zoneope', function() {
	console.log("pass bien par la ok la");

	var years = $('.item').map(function() {
		return $(this).data('year');
	}).get();
	console.log(years);
});

window.nbTryEnableRecaptcha = 0;
window.enableRecaptcha = function(){
	if ($('#g-recaptcha').length) {

		if(!grecaptcha || !grecaptcha.render){
			window.nbTryEnableRecaptcha++;
			if(window.nbTryEnableRecaptcha < 3){
				console.log("grecaptcha was not loaded yet, retrying... (attempt "+window.nbTryEnableRecaptcha+")");
				setTimeout(window.enableRecaptcha,3000);
			}
		}else{
			recaptchaSiteKey = $( "#g-recaptcha" ).data("sitekey");
			grecaptcha.render('g-recaptcha', {
				sitekey: recaptchaSiteKey,
				callback: function(response) {
					console.log(response);
				}
			});
		}
	}

}

$(document).ready(function(){
	if(isLoginClickParameter()){
		onClickLoginBtn();
	}

	enableRecaptcha();
	
	/* Nouvelle page upcoming activities*/ 
	if($('body').hasClass('meetingreports') || $('body').hasClass('upcoming-activities')){

		var years = $('.item').map(function() {
			return $(this).data('year');
		}).get();
		/*console.log(years);*/

		var unique = years.filter(function(itm, i, years) {
			return i == years.indexOf(itm);
		});

		var firstyear = unique[0];

		$.each(unique, function( index, value ) {
			/*alert( index + ": " + value );*/
			$('#triyears').append( '<div class="buttontriyear btnyear'+value+'" data-yeartosort="'+value+'">'+value+'</div>' );
		});

		$( ".item" ).hide();
		$( ".item.annee" +firstyear).fadeIn();
		$( ".btnyear" +firstyear).addClass("active");

		$( ".buttontriyear" ).on( "click", function() {
			yeartoshow = $(this).data('yeartosort');
			$( ".item" ).hide();
			$( ".item.annee" +yeartoshow).fadeIn();
			$( ".buttontriyear").removeClass("active");
			$( ".btnyear" +yeartoshow).addClass("active");
		});
	};
 

});

if ($('.cms_forms_msg').length) {
	$('.ressource-details-btn').css('display', 'inline-block');
}else {
	$('.ressource-details-btn').hide();
};



