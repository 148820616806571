
function monthToString(month){
	var monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
	  	"July",
	  	"August",
	  	"September",
	  	"October",
	  	"November",
	  	"December"
	];
	return monthNames[month];
}


window.formatDate = function(date,separator,showDay){
	if(!separator){
		separator = ' ';
	}
	if(showDay == null){
		showDay = false;
	}
	var dt = new Date(date);

	var string = '';
	if(showDay){
		string += dt.getUTCDate() + separator;
	}
	string += monthToString(dt.getMonth()) + separator;
	string += dt.getFullYear();
	return string;
}