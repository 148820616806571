
$(document).ready(function(){

    $('.btncalendar').click(function(e){
		e.preventDefault();
		let $item = $(this);

		let dateStart = $item.data('datestart');
		let dateEnd = $item.data('dateend');
		let timeStart = $item.data('timestart');
		let timeEnd = $item.data('timeend');
		let lieu = $item.data('lieu');
		let titre = $item.data('titre');
		let description = $item.data('description');

		

		let ics = '';
		if(timeStart && timeEnd){
			let msgData1 = dateStart + "T" + timeStart;
			let msgData2 = dateEnd + "T" + timeEnd;
			let msgData3 = lieu;
			let msgData4 = titre;

			ics += "BEGIN:VCALENDAR" + "\n";
			ics += "X-WR-TIMEZONE:Etc/GMT" + "\n";
			ics += "VERSION:2.0" + "\n";
			ics += "PRODID:-//IADS//NONSGML v1.0//EN" + "\n";
			ics += "BEGIN:VEVENT" + "\n";
			ics += "UID:me@google.com" + "\n";
			ics += "DTSTAMP:20120315T170000Z" + "\n";
			ics += "ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com" + "\n";
			ics += "ORGANIZER;CN=Me:MAILTO::me@gmail.com" + "\n";
			ics += "DTSTART:" + msgData1 +"" + "\n";
			ics += "DTEND:" + msgData2 +"" + "\n";
			ics += "DESCRIPTION:"+description+"" + "\n";
			ics += "LOCATION:" + msgData3 + "" + "\n";
			ics += "SUMMARY:" + msgData4 + "" + "\n";
			ics += "END:VEVENT" + "\n";
			ics += "END:VCALENDAR" + "\n";
		}else{

			let msgData1 = dateStart;
			let msgData2 = dateEnd;
			let msgData3 = lieu;
			let msgData4 = titre;

			ics += "BEGIN:VCALENDAR" + "\n";
			ics += "X-WR-TIMEZONE:Etc/GMT" + "\n";
			ics += "VERSION:2.0" + "\n";
			ics += "PRODID:-//IADS//NONSGML v1.0//EN" + "\n";
			ics += "BEGIN:VEVENT" + "\n";
			ics += "UID:me@google.com" + "\n";
			ics += "DTSTAMP:20120315T170000Z" + "\n";
			ics += "ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com" + "\n";
			ics += "ORGANIZER;CN=Me:MAILTO::me@gmail.com" + "\n";
			ics += "DTSTART;VALUE=DATE:" + msgData1 +"" + "\n";
			ics += "DTEND;VALUE=DATE:" + msgData2 +"" + "\n";
			ics += "DESCRIPTION:"+description+"" + "\n";
			ics += "LOCATION:" + msgData3 + "" + "\n";
			ics += "SUMMARY:" + msgData4 + "" + "\n";
			ics += "END:VEVENT" + "\n";
			ics += "END:VCALENDAR" + "\n";

		}

        //window.open( "data:text/calendar;charset=utf8," + escape(ics));

        let content = "data:text/calendar;charset=utf8," + escape(ics);
        let filename = 'iads-event.ics';

		var element = document.createElement('a');
		element.setAttribute('href', content);
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
    });
});